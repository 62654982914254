import React, { useState, useContext, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import jwt_decode from "jwt-decode"
import { theme } from "../theme"
import { BrinkContext } from "../components/context/BrinkContext"
import { PageProvider } from "../components/context/PageContext"
import {
  containerSmallMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../constants"
import Head from "../components/Head"
import Header from "../components/checkout/Header"
import Link from "../components/Link"
import Loader from "../components/ui/Loader"
import Button from "../components/ui/Button"
import ShippingCheckbox from "../components/checkout/Address/ShippingCheckbox"
import Item from "../components/cart/Item"
import Totals from "../components/cart/Totals"
import Box from "../components/checkout/Box"
import BillingAddress from "../components/checkout/Address/BillingAddress"
import ShippingAddress from "../components/checkout/Address/ShippingAddress"
import Shipping from "../components/checkout/Shipping"
import Discount from "../components/checkout/Discount"
import Summary from "../components/checkout/Summary"
import Payment from "../components/checkout/Payment"
import PaymentLogos from "../components/checkout/PaymentLogos"

import { parsePhoneNumberFromString } from "libphonenumber-js/min"
import * as events from "../components/context/utils/events"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${(p) => p.theme.colors.background};
`

const Body = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${containerSmallMaxWidth};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 3rem 2rem;

  ${MEDIA_MIN_LARGE} {
    overflow-x: visible;
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

const GridItem = styled.div`
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: calc(50% - 2rem);
  }
`

const Left = styled(GridItem)`
  order: 2;

  ${MEDIA_MIN_MEDIUM} {
    order: 1;
  }
`

const Right = styled(GridItem)`
  order: 1;

  ${MEDIA_MIN_MEDIUM} {
    order: 2;
  }
`

const AddressBox = styled(Box)`
  margin: 0 0 2rem;
  padding-bottom: 1rem;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 2rem;
  }
`

const ShippingBox = styled(Box)`
  margin: 0 0 2rem;
  padding-bottom: 0rem;
`
const InfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 2rem;
  padding-bottom: 0rem;

  div {
    padding-bottom: 2rem;
  }
`

const Cart = styled(Box)`
  margin-bottom: 2rem;
  padding: 2rem 2rem 1rem;

  h3 {
    width: 100%;

    span {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }

      ${MEDIA_MIN_MEDIUM} {
        &:first-child {
          display: block;
        }

        &:last-child {
          display: none;
        }
      }
    }
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 3rem 2rem;
  }
`

const Items = styled.div`
  width: 100%;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
`

const AddDiscountLinK = styled.div`
  width: 100%;
  padding: 0 0 2rem;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  a {
    margin-left: 0.5rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    border-bottom: none;
  }
`

const ContinueButton = styled(Button)`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  margin-top: 1rem;
`

const PaymentFailure = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  margin-bottom: 3rem;
  background: #f9c7c5;
  color: #9e362c;

  i {
    font-size: 3rem;
    margin-right: 1rem;
  }
`

const Sticky = styled.div`
  ${MEDIA_MIN_MEDIUM} {
    position: sticky;
    top: 3rem;
  }
`

const DifferentShippingAddress = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 2.4rem;
`

const DifferentShippingAddressCheckbox = styled(ShippingCheckbox)`
  margin-right: 1rem;
  padding: 0;
`

const CheckoutPage = ({ data: { allSanityShippingOption }, pageContext }) => {
  const [showDiscountForm, setShowDiscountForm] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const { t } = useTranslation("translations")
  const location = useLocation()
  const { control, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  })
  const { sanityConfigCheckoutInfo, generalSettings } = pageContext
  const {
    cart,
    billingAddress,
    setBillingAddress,
    shippingAddress,
    setShippingAddress,
    useDifferentShippingAddress,
    setUseDifferentShippingAddress,
    isLoading,
    setIsLoading,
    currentStore,
    getSetCart,
    brinkSessionId
  } = useContext(BrinkContext)

  const { infoTitle, storeInfo } = sanityConfigCheckoutInfo
  const infoText = storeInfo[currentStore.countryCode.toString().toLowerCase()]

  const isEmptyCart = (cart) =>
    cart.cartItems.filter((p) => p.type === "productVariant").length === 0

  const { checkoutFlowEnabled } = generalSettings

  useEffect(() => {
    if (isEmptyCart(cart) || !checkoutFlowEnabled) {
      navigate("/")
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, generalSettings])

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  useMountEffect(() => {
    const startCheckout = async () => {
      const params = new URLSearchParams(location.search)
      let storedCart = cart
      const token = params.get("cart")
      if (token) {
        storedCart = await getCart(token)
      }

      if (!isEmptyCart(storedCart)) {

        events.initiateCheckout(storedCart, currentStore, brinkSessionId)
      }
    }
    startCheckout()
  })

  const getCart = async (token) =>
    await getSetCart(jwt_decode(token).sessionId, token)

  const cartQuantity = cart.cartItems.reduce((prev, cur) => {
    if (cur.type === "shippingOption") return prev
    return prev + cur.quantity
  }, 0)

  const formatPhoneNumber = (data) => {
    const phoneNumber = parsePhoneNumberFromString(
      data || "",
      currentStore.countryCode
    )
    return phoneNumber ? phoneNumber.number : ""
  }

  const onContinueToPayment = (data) => {
    setIsLoading(true)

    const regionAvailableCountries = ["US", "CA"]
    const updatedBillingAddress = {
      ...billingAddress,
      ...data.billing,
      phone: formatPhoneNumber(data.billing.phone),
      ...(!regionAvailableCountries.includes(currentStore.countryCode) && {
        region: undefined
      })
    }

    const updatedShippingAddress = {
      ...shippingAddress,
      ...data.shipping,
      phone: data.shipping && formatPhoneNumber(data.shipping.phone),
      country: billingAddress.country,
      ...(!regionAvailableCountries.includes(currentStore.countryCode) && {
        region: undefined
      })
    }

    setBillingAddress(updatedBillingAddress)
    setShippingAddress(
      data.shipping ? updatedShippingAddress : updatedBillingAddress
    )
    events.identify(data.shipping ? updatedShippingAddress : updatedBillingAddress)
    setShowSummary(true)
    setShowPayment(true)
  }

  if (!checkoutFlowEnabled) return null

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <PageProvider pageContext={pageContext}>
        {cart && cart.cartItems.length > 0 && (
          <Wrapper className="top">
            <Helmet>
              <html lang="en" className="enable-sticky" />
              <body className="enable-sticky" />
            </Helmet>
            <Head meta={{ title: "Checkout" }} />
            <Header />
            <Body>
              <Loader isLoading={isLoading} />
              {location.state && location.state.error === "payment-failure" && (
                <PaymentFailure>
                  <i className="fal fa-exclamation-circle"></i>
                  {t(
                    "Something went wrong with your payment. Please try another option or contact our support."
                  )}
                </PaymentFailure>
              )}
              <Grid>
                <Left>
                  {showSummary && (
                    <Box>
                      <h3>{t("Summary")}</h3>
                      <Summary
                        setShowPayment={setShowPayment}
                        setShowSummary={setShowSummary}
                      />
                    </Box>
                  )}
                  {!showPayment && (
                    <form onSubmit={handleSubmit(onContinueToPayment)}>
                      <ShippingBox>
                        <h3>{t("Shipping")}</h3>
                        <Shipping
                          sanityShippingOptions={allSanityShippingOption}
                        />
                      </ShippingBox>
                      {infoText && (
                        <InfoBox>
                          <h3>{infoTitle}</h3>
                          <div>{infoText}</div>
                        </InfoBox>
                      )}
                      <AddressBox>
                        <h3>{t("Address")}</h3>
                        <BillingAddress
                          control={control}
                          errors={errors}
                          showPayment={showPayment}
                          setValue={setValue}
                        />
                        <DifferentShippingAddress>
                          <DifferentShippingAddressCheckbox
                            handleOnChange={() =>
                              setUseDifferentShippingAddress(
                                !useDifferentShippingAddress
                              )
                            }
                            value={useDifferentShippingAddress}
                          />
                          {t("Use different shipping address")}
                        </DifferentShippingAddress>
                        {useDifferentShippingAddress && (
                          <ShippingAddress
                            control={control}
                            errors={errors}
                            showPayment={showPayment}
                            setValue={setValue}
                          />
                        )}
                      </AddressBox>

                      <ContinueButton hasIcon type="submit">
                        {t("Continue to payment")}{" "}
                        <i className="fas fa-arrow-right"></i>
                      </ContinueButton>
                      <PaymentLogos />
                    </form>
                  )}
                  {showPayment && <Payment />}
                </Left>
                <Right>
                  <Sticky>
                    <Cart>
                      <h3>
                        <span>{t("Cart")}</span>
                        <span>
                          {cartQuantity}{" "}
                          {cartQuantity === 1 ? t("product") : t("products")}
                        </span>
                      </h3>
                      <Items>
                        {cart.cartItems.map((item) => (
                          <Item item={item} key={item.id} showControls />
                        ))}
                      </Items>
                      <Totals />
                    </Cart>
                    <AddDiscountLinK>
                      <strong>{t("Do you have a discount code?")}</strong>
                      <Link
                        underline
                        color="black"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault()
                          setShowDiscountForm(!showDiscountForm)
                        }}
                      >
                        {t("Click here to use it")}
                      </Link>
                    </AddDiscountLinK>
                    {showDiscountForm && (
                      <Box>
                        <Discount />
                      </Box>
                    )}
                  </Sticky>
                </Right>
              </Grid>
            </Body>
          </Wrapper>
        )}
      </PageProvider>
    </ThemeProvider>
  )
}

export default CheckoutPage

export const query = graphql`
  query {
    allSanityShippingOption {
      nodes {
        id: _id
        displayName {
          en
        }
        description {
          en
        }
        mainImage {
          asset {
            url
          }
        }
      }
    }
  }
`
